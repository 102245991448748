import { AppState } from 'store/root.reducer'

export const selectAdminState = (state: AppState) => state?.admin

export const selectDeactivateUserLoadingStatus = (state: AppState) => selectAdminState(state)?.deactivateUserLoadingStatus

export const selectCompanies = (state: AppState) => selectAdminState(state)?.companies

export const selectGetCompaniesLoadingStatus = (state: AppState) => selectAdminState(state)?.getCompaniesLoadingStatus

export const selectCustomers = (state: AppState) => selectAdminState(state)?.customers

export const selectGetCustomersLoadingStatus = (state: AppState) => selectAdminState(state)?.getCustomersLoadingStatus

export const selectEditCustomerLoadingStatus = (state: AppState) => selectAdminState(state)?.editCustomerLoadingStatus

export const selectCreateCompanyDetailsLoadingStatus = (state: AppState) => selectAdminState(state)?.createCompanyDetailsLoadingStatus

export const selectCreateCustomerLoadingStatus = (state: AppState) => selectAdminState(state)?.createCustomerLoadingStatus

export const selectEditCustomerSettingsLoadingStatus = (state: AppState) => selectAdminState(state)?.editCustomerSettingsLoadingStatus

export const selectUsers = (state: AppState) => selectAdminState(state)?.users

export const selectLoadingUsersStatus = (state: AppState) => selectAdminState(state)?.loadingGetUsers

export const selectCreateUserLoadingStatus = (state: AppState) => selectAdminState(state)?.loadingCreateUser

export const selectEditUserLoadingStatus = (state: AppState) => selectAdminState(state)?.loadingEditUser
