import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { TemplateState } from 'store/template/template.reducer'
import { selectTemplate } from 'store/template/template.selectors'
import { getTemplateRequest } from 'store/template/template.actions'
import { selectCurrentUser } from 'store/users/users.selectors'
import { selectGetCompaniesLoadingStatus } from 'store/admin/admin.selectors'
import { selectCurrentCompanyDetails } from 'store/billing/billing.selectors'
import { getCurrentCompanyDetailsRequest, updateCurrentCompanyDetailsRequest } from 'store/billing/billing.actions'

import { TemplateType } from 'types/core/template'
import { Props } from 'types/core/props'

export const useBillingCompanyDetails = () => {
  const dispatch = useDispatch()
  const companyDetails = useSelector(selectCurrentCompanyDetails)
  const status = useSelector(selectGetCompaniesLoadingStatus)
  const templateState: TemplateState = useSelector(selectTemplate)
  const currentUser = useSelector(selectCurrentUser)

  const [billingCompanyDetails, setBillingCurrentCompanyDetails] = useState<Props>({})
  const [initialDetails, setInitialDetails] = useState<Props>({})
  const [formState, setFormState] = useState<{ isVisible: boolean }>({ isVisible: false })

  useEffect(() => {
    dispatch(getCurrentCompanyDetailsRequest())
  }, [currentUser, dispatch])

  useEffect(() => {
    dispatch(getTemplateRequest({ name: TemplateType.COMPANY_DETAILS }))
  }, [dispatch])

  const handleOpen = useCallback(() => {
    setFormState({ isVisible: true })
    setBillingCurrentCompanyDetails(companyDetails)
    setInitialDetails(companyDetails)
  }, [companyDetails])

  const handleClose = useCallback(() => {
    setFormState({ isVisible: false })
  }, [])

  const onChange = useCallback((value: any, id: any) => {
    setBillingCurrentCompanyDetails((prev) => ({ ...prev, [id]: value }))
  }, [])

  const [changedDetails, detailsIsChanged] = useMemo(() => {
    const entries = Object.entries(billingCompanyDetails)
    const filteredEntries = entries.filter(([key, value]) => value !== initialDetails[key])
    return [Object.fromEntries(filteredEntries), filteredEntries.length > 0]
  }, [initialDetails, billingCompanyDetails])

  const onSubmit = useCallback(() => {
    dispatch(updateCurrentCompanyDetailsRequest({ company: changedDetails }))
    dispatch(getCurrentCompanyDetailsRequest())
  }, [dispatch, changedDetails])

  return {
    templateLoadingStatus: templateState.loadingStatus,
    template: templateState.templates[TemplateType.COMPANY_DETAILS],
    detailsLoadingStatus: status,
    billingCompanyDetails,
    detailsIsChanged,
    handleOpen,
    handleClose,
    onChange,
    onSubmit,
    formState,
  }
}
