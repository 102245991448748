import { createAction } from 'redux-actions'
import { Props } from 'types/core/props'
import { EntityValue } from '../../types/core/entity-value'

export const GET_BILLING_SETTINGS_REQUEST = 'GET_BILLING_SETTINGS_REQUEST'
export const getBillingSettingsRequest = createAction<void>(GET_BILLING_SETTINGS_REQUEST)

export const GET_BILLING_SETTINGS_SUCCESS = 'GET_BILLING_SETTINGS_SUCCESS'
export const getBillingSettingsSuccess = createAction<any>(GET_BILLING_SETTINGS_SUCCESS)

export const GET_BILLING_SETTINGS_FAILURE = 'GET_BILLING_SETTINGS_FAILURE'
export const getBillingSettingsFailure = createAction<any>(GET_BILLING_SETTINGS_FAILURE)

export const GET_CURRENT_BILLING_SETTINGS_REQUEST = 'GET_CURRENT_BILLING_SETTINGS_REQUEST'
export const getCurrentBillingSettingsRequest = createAction<void>(GET_CURRENT_BILLING_SETTINGS_REQUEST)

export const GET_CURRENT_BILLING_SETTINGS_SUCCESS = 'GET_CURRENT_BILLING_SETTINGS_SUCCESS'
export const getCurrentBillingSettingsSuccess = createAction<any>(GET_CURRENT_BILLING_SETTINGS_SUCCESS)

export const GET_CURRENT_BILLING_SETTINGS_FAILURE = 'GET_CURRENT_BILLING_SETTINGS_FAILURE'
export const getCurrentBillingSettingsFailure = createAction<any>(GET_CURRENT_BILLING_SETTINGS_FAILURE)

export const SET_BILLING_SETTINGS_REQUEST = 'SET_BILLING_SETTINGS_REQUEST'
export const setBillingSettingsRequest = createAction<{ customer: number; settings: any }>(SET_BILLING_SETTINGS_REQUEST)

export const SET_BILLING_SETTINGS_SUCCESS = 'SET_BILLING_SETTINGS_SUCCESS'
export const setBillingSettingsSuccess = createAction<any>(SET_BILLING_SETTINGS_SUCCESS)

export const SET_BILLING_SETTINGS_FAILURE = 'SET_BILLING_SETTINGS_FAILURE'
export const setBillingSettingsFailure = createAction<any>(SET_BILLING_SETTINGS_FAILURE)

export const GET_COMPANY_ACCOUNT_REQUEST = 'GET_COMPANY_ACCOUNT_REQUEST'
export const getCompanyAccountRequest = createAction<string>(GET_COMPANY_ACCOUNT_REQUEST)

export const GET_COMPANY_ACCOUNT_SUCCESS = 'GET_COMPANY_ACCOUNT_SUCCESS'
export const getCompanyAccountSuccess = createAction<Props>(GET_COMPANY_ACCOUNT_SUCCESS)

export const GET_COMPANY_ACCOUNT_FAILURE = 'GET_COMPANY_ACCOUNT_FAILURE'
export const getCompanyAccountFailure = createAction<void>(GET_COMPANY_ACCOUNT_FAILURE)

export const UPDATE_CURRENT_COMPANY_DETAILS_REQUEST = 'UPDATE_CURRENT_COMPANY_DETAILS_REQUEST'
export const updateCurrentCompanyDetailsRequest = createAction<{ company: Props }>(UPDATE_CURRENT_COMPANY_DETAILS_REQUEST)

export const UPDATE_CURRENT_COMPANY_DETAILS_SUCCESS = 'UPDATE_CURRENT_COMPANY_DETAILS_SUCCESS'
export const updateCurrentCompanyDetailsSuccess = createAction<void>(UPDATE_CURRENT_COMPANY_DETAILS_SUCCESS)

export const UPDATE_CURRENT_COMPANY_DETAILS_FAILURE = 'UPDATE_CURRENT_COMPANY_DETAILS_FAILURE'
export const updateCurrentCompanyDetailsFailure = createAction<Error>(UPDATE_CURRENT_COMPANY_DETAILS_FAILURE)

export const GET_CURRENT_COMPANY_DETAILS_REQUEST = 'GET_CURRENT_COMPANY_DETAILS_REQUEST'
export const getCurrentCompanyDetailsRequest = createAction<void>(GET_CURRENT_COMPANY_DETAILS_REQUEST)

export const GET_CURRENT_COMPANY_DETAILS_SUCCESS = 'GET_CURRENT_COMPANY_DETAILS_SUCCESS'
export const getCurrentCompanyDetailsSuccess = createAction<EntityValue>(GET_CURRENT_COMPANY_DETAILS_SUCCESS)

export const GET_CURRENT_COMPANY_DETAILS_FAILURE = 'GET_CURRENT_COMPANY_DETAILS_FAILURE'
export const getCurrentCompanyDetailsFailure = createAction<void>(GET_CURRENT_COMPANY_DETAILS_FAILURE)
