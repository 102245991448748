import React from 'react'
import { useSelector } from 'react-redux'

import { AppSettings } from 'types/user'

import { selectCurrentUser } from 'store/users/users.selectors'

import { StatisticBodyType } from 'components/pages/StatisticPage'
import Statistic from 'components/molecules/Statistic'

interface StatisticBodyProps {
  type: StatisticBodyType
}

export const StatisticBody: React.FC<StatisticBodyProps> = ({ type }) => {
  const user = useSelector(selectCurrentUser)

  switch (type) {
    case StatisticBodyType.FEED:
      return <Statistic src={user?.settings?.[AppSettings.STATISTIC_FEED] || ''} withoutCache />

    case StatisticBodyType.EXPERT:
      return <Statistic src={user?.settings?.[AppSettings.STATISTIC_ABOUT_EXPERTS] || ''} />

    case StatisticBodyType.QUALITY_CONTROL:
      return <Statistic src={user?.settings?.[AppSettings.STATISTIC_FOR_QUALITY_CONTROL] || ''} withoutCache />

    default:
      throw new Error('Не выбран пункт меню')
  }
}

export default StatisticBody
