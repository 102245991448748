import { call, put, select } from 'redux-saga/effects'

import { addHttpError } from 'store/http-error/http-error.actions'
import { selectToken } from 'store/users/users.selectors'

import { getCurrentCompanyDetailsFailure, getCurrentCompanyDetailsSuccess } from 'store/billing/billing.actions'
import { getCurrentCompanyDetails } from 'requests/company'

export function* getCurrentCompanyDetailsSaga() {
  try {
    const token = yield select(selectToken)

    const response = yield call(getCurrentCompanyDetails, token)

    yield put(getCurrentCompanyDetailsSuccess(response))
  } catch (error) {
    yield put(
      addHttpError({
        error,
        header: 'Получение реквизитов компании',
        defaultText: 'Не удалось получить реквизиты компании.',
      }),
    )
    console.error(`[getCurrentCompanyDetailsSaga]: ${error}`)
    yield put(getCurrentCompanyDetailsFailure(error))
  }
}
