import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import { LoadingStatus } from 'types/loading'
import { Template } from 'types/core/template'

import { createInvoice } from 'requests/billing'

import { selectToken } from 'store/users/users.selectors'
import { selectCompanyAccount, selectGetCompanyAccountLoadingStatus } from 'store/billing/billing.selectors'
import InputControl from '../InputControl'
import Button from '../Button'
import BillingCompanyDetailsForm from './billingCompanyDetailsForm'
import { useBillingCompanyDetails } from './hook/use-company-details'

const InvoiceForm: React.FC = () => {
  const companyAccount = useSelector(selectCompanyAccount)
  const loadingStatus = useSelector(selectGetCompanyAccountLoadingStatus)
  const token = useSelector(selectToken)

  const [amount, setAmount] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const {
    detailsLoadingStatus,
    template,
    billingCompanyDetails,
    onChange,
    onSubmit,
    detailsIsChanged,
    handleOpen,
    handleClose,
    formState,
  } = useBillingCompanyDetails()

  const handleAmountChange = useCallback((value: string) => {
    setAmount(value)
    setErrorMessage('')
  }, [])

  const generateInvoice = useCallback(() => {
    const numericAmount = parseFloat(amount)
    if (numericAmount <= 0) {
      setErrorMessage('Пожалуйста, введите корректную сумму.')
    } else {
      setAmount('')
      createInvoice({ amount }, token as string).then((data) => {
        window.location.href = data.link
      })
    }
  }, [amount, token])

  const isLoading = loadingStatus === LoadingStatus.PENDING

  if (!companyAccount?.id) return null

  return (
    <div>
      <div className="mb-2 mt-3 font-weight-bold">
        <InputControl
          type="number"
          id="invoiceAmount"
          className="col-lg-3 col-md-6 col-sm-12"
          value={amount}
          onChangeHandler={handleAmountChange}
          label="Введите сумму для формирования счета"
          placeholder="Введите сумму"
          invalidMessage={errorMessage}
        />
      </div>
      <div className="mb-2 font-weight-bold d-flex flex-column w-25">
        <Button onClick={generateInvoice} disabled={isLoading || !amount} isLoading={isLoading}>
          Скачать счет
        </Button>
        <Button className="mt-1" variant="outline-primary" onClick={handleOpen}>
          Изменить реквизиты
        </Button>
      </div>
      <BillingCompanyDetailsForm
        isVisible={formState.isVisible}
        companyDetails={billingCompanyDetails}
        detailsIsChanged={detailsIsChanged}
        handleClose={handleClose}
        loadingStatus={detailsLoadingStatus}
        onChange={onChange}
        onSubmit={onSubmit}
        template={template as Template}
      />
    </div>
  )
}

export default InvoiceForm
