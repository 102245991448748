import { put, select, call } from 'redux-saga/effects'
import { Action } from 'redux-actions'

import { selectToken } from 'store/users/users.selectors'
import { addAlert } from 'store/alerts/alerts.actions'
import { addHttpError } from 'store/http-error/http-error.actions'

import { AlertVariant } from 'types/alert'
import { Props } from 'types/core/props'

import { updateCurrentCompanyDetails } from 'requests/company'
import { updateCurrentCompanyDetailsFailure, updateCurrentCompanyDetailsSuccess } from '../billing.actions'

export function* updateCurrentCompanyDetailsSaga(action: Action<{ company: Props }>) {
  try {
    const token = yield select(selectToken)

    yield call(updateCurrentCompanyDetails, action.payload.company, token)

    yield put(updateCurrentCompanyDetailsSuccess())
    yield put(
      addAlert({
        text: `Реквизиты изменены.`,
        lifetime: 3000,
        variant: AlertVariant.SUCCESS,
      }),
    )
  } catch (error) {
    yield put(updateCurrentCompanyDetailsFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Изменение реквизитов.',
        defaultText: 'Не удалось изменить реквизиты.',
      }),
    )
    console.error(`[updateCurrentCompanyDetailsSaga]: ${error}`)
  }
}
